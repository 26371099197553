<template>
  <div class="content-page">
    <!-- 面包屑导航 -->
    <b-card class="breadcrumb mb-3">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
        <el-breadcrumb-item>My Exchange</el-breadcrumb-item>
      </el-breadcrumb>
    </b-card>
    <!-- 左侧边栏 -->
    <div class="row mt-2">
      <div class="col-3">
        <b-card>
          <p class="p-nav cursor-pointer" v-for="(v, i) in navList" :key="i" @click="changeType(v)">
            <a :class="{'active':type==v.type}" href="javascript:;">{{v.name}}</a>
          </p>
        </b-card>
      </div>
      <div class="col-9 list_item">
        <div v-if="pointsOrderList.length">
          <b-card class="mb-3" v-for="(v, i) in pointsOrderList" :key="i">
            <!-- 商品标题 -->
            <div class="row">
              <div class="col-3 title">
                <h5><i class="ri-store-2-line"></i> JCI</h5>
              </div>
              <div class="col-6 text-center">OrderID : {{v.order_sn}}</div>
              <div class="col-3 text-right pending" v-if="v.pickup_method==1">{{{0:'Pending Acceptance',1:'Pending
                Shipment',2:'Pending
                Confirmation',3:'Completed',4:'Rejected'}[v.order_status]}}</div>
              <div class="col-3 text-right pending" v-else>{{{0:'Pending Acceptance',1:'Pending Pickup',2:'Pending
                Confirmation',3:'Completed',4:'Rejected'}[v.order_status]}}</div>
            </div>
            <hr />
            <div class="product_detail">
              <!-- 商品详情 -->
              <div class="row  cursor-pointer" :class="[{'b-border':v.order_status==2},{'pb-3':v.order_status==2}]"
                @click="$router.push(`/scoreorderdetails?id=${v.order_id}`)">
                <div class="col-md-3 col-sm-12">
                  <img class="w-75" :src="v.product_image" alt="" />
                </div>
                <div class="col-md-3 col-sm-12 pl-md-0 pl-sm-5">
                  <p class="title">{{v.product_name}}</p>
                  <div class="d-flex flex-wrap" style="color: #666;">
                    <div v-for="(vv, ii) in v.product_attr" :key="ii" class="d-flex">
                      <p class="m-0">{{vv.attr_name}} : {{vv.attr_value}}</p>
                      <span v-if="ii!=v.product_attr.length-1">；</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-12 text-md-center pl-sm-5">
                  <p class="title m-0 text-right">{{v.points}} Points</p>
                  <p class="text-right">*{{v.quantity}}</p>
                </div>
                <div class="col-md-4 col-sm-12 text-md-right pl-sm-5">
                  <p class="pr-sm-3 title">{{v.points_amount}} Points</p>
                </div>
              </div>
              <div class="text-right mt-3" v-if="v.order_status==2">
                <button class="btn" @click="pointsOrderOperate(v.order_id)">Confirm</button>
              </div>
            </div>
          </b-card>
        </div>
        <div class="d-flex justify-content-center align-items-center" style="background:#fff;width: 100%;height: 100%;" v-else>
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
        <div class="d-flex justify-content-end">
          <Pagination :total="total" @changeCurrentPage="changeCurrentPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Pagination from "@/components/ComComponents/Pagination";
  export default {
    components: { Pagination },
    data() {
      return {
        pointsOrderList: [],
        navList: [
          { name: 'All', type: 6 },
          { name: 'Pending Acceptance', type: 0 },
          { name: 'Pending Shipment', type: 1 },
          { name: 'Pending Pickup', type: 2 },
          { name: 'Pending Confirmation', type: 3 },
          { name: 'Completed', type: 4 },
          { name: 'Rejected', type: 5 },
        ],
        type: 6,
        total: 0
      };
    },

    created() {
      this._pointsOrderList()
    },

    methods: {
      // 切换导航
      changeType(v) {
        this.type = v.type
        this._pointsOrderList()
      },

      // 积分订单列表
      async _pointsOrderList(page = 1) {
        const data = {
          page,
          order_type: this.type,
          per_page: "",
        };
        let res = await this.$http.pointsOrderList(data)
        if (res.status == 200) {
          this.pointsOrderList = res.data.data || []
          this.total = res.data.total || 0
        }
      },

      changeCurrentPage(e) {
        this._pointsOrderList(e);
      },

      // 积分订单确认操作
      async pointsOrderOperate(order_id) {
        let res =await this.$http.pointsOrderOperate({ order_id})
        if(res.status==200) {
          this._pointsOrderList();
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .breadcrumb {
    background: #fff;
    height: 60px;
    margin-bottom: 0;

    .card-body {
      display: flex;
      align-items: center;
      padding: 0;
    }

    ::v-deep .el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .active {
    color: #0aa1eb !important;
  }

  .title {
    color: #333;
  }

  .p-nav {
    padding: 13px;
    border-bottom: 1px solid #f1f1f1;
    margin: 0;

    &:last-child {
      border: 0;
    }

    a {
      color: #666666;
    }
  }

  .btn {
    width: 100px;
    height: 35px;
    background: #0aa1eb;
    border: 1px solid #0aa1eb;
    color: #fff;
  }

  .pending {
    color: #ffba68;
  }

  .def {
    color: #fff;
    background: #0aa1eb;
  }

  .b-border {
    border-bottom: 1px solid #f1f1f1;
  }

  .list_item .product_detail {
    img {
      display: block;
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }
</style>